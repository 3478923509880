import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import AboutNav from 'components/Public/AboutNav'
import { Button, IconButton } from 'components/Shared/_Button'

import timeline from 'constant/public-timeline.constant'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  --width: 100%;
  ${ p => p.theme.mq.md`
    --width: 75vw;
  `}
  width: var(--width);
  margin-bottom: calc(var(--margin-default) * 5);
  .ev-ccc {
    --border-color: rgba(var(--color-green), 0.9);
  }
  .ev-special {
    --border-color: rgba(var(--color-purple), 0.9);
  }
  .ev-camp--local {
    --border-color: rgba(var(--color-blue), 0.9);
  }
  .ev-camp--intl {
    --border-color: #edb74a;
  }
`
const EventLabel = styled.section`
  --font-size: calc(var(--font-size-default) * 0.8);
  width: 100%;
  text-align: center;
  margin-bottom: 5rem;

  * {
    display: inline;
    padding-left: 0.75rem;
    padding-right: 2rem;
    margin-bottom: 0;
  }

  p {
    --margin-top: 0;
    --text-align: left;
    border-left: 0.5rem solid var(--border-color);
  }
`
const YearLabel = styled.section`
  --justify-content: center;
  ${p => p.theme.flex}
  margin-bottom: calc(var(--margin-default)*2);

  .icon {
    --size: calc(var(--font-size-default) * 2.5);
    --color: var(--color-primary);
  }
  .disabled {
    --color: var(--color-gray);
    opacity: 0.2;
  }
  .year {
    --font-size: calc(var(--font-size-default) * 0.9);
    --padding: 0 clamp(1rem, 3.5vw, 4rem);
    --border-radius: 0;
    --background-color: transparent;
    --color: var(--color-gray);
    --hover-color: var(--color-primary);
    height: 4rem;
    border-bottom: 0.2rem solid var(--color-secondary);
    position: relative;
    opacity: 0.8;

    &::after {
      --size: 1rem;
      width: var(--size);
      height: var(--size);
      content: '';
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, calc(50% + 2rem));
      border-radius: 50%;
      background-color: var(--color-secondary);
    }
  }
  .active {
    --color: var(--color-secondary);
    &::after {
      --size: 1.6rem;
    }
  }
`
const Events = styled.section`
  --column-count: 2;
  ${p => p.theme.mq.md`
    --column-count: 3;
  `}
  column-count: var(--column-count);
  column-gap: 2rem;
`
const Event = styled.div`
  --grid-template-rows: 1fr auto;
  --justify-items: left;
  ${p => p.theme.grid}
  ${p => p.theme.fullbleed}
  break-inside: avoid;
  margin-bottom: 2rem;
  border-left: 0.75rem solid var(--border-color);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  p,
  h5 {
    --margin-top: 0;
    --text-align: left;
    padding: 0.75rem 1rem;
    line-height: 1.4;
    &:last-child {
      --margin-bottom: 1rem;
    }
  }
  h5 {
    color: var(--color-primary);
    opacity: 0.9;
  }
  .time {
    font-size: calc(var(--font-size-default) * 0.8);
    margin-bottom: -1.4rem;
  }
  .place {
    font-size: calc(var(--font-size-default) * 0.85);
  }
`
//#endregion

const Timeline = ({ data, location }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.history

  const [index, setIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(index)
  const [partialTimeline, setPartialTimeline] = useState(
    timeline.slice(index, index + 5)
  )

  const onClick = partialIndex => {
    setCurrentIndex(partialIndex + index)
  }
  const nextYear = () => setIndex(prev => prev + 1)
  const prevYear = () => setIndex(prev => prev - 1)

  useEffect(() => {
    setPartialTimeline(timeline.slice(index, index + 5))
  }, [index])

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <AboutNav location={location} />
      <Title>團史簡要</Title>
      <Wrapper>
        <EventLabel>
          <p className='ev-ccc'>晶晶例行活動</p>
          <p className='ev-special'>國內外特殊活動</p>
          <p className='ev-camp--local'>國內營隊</p>
          <p className='ev-camp--intl'>國際營隊</p>
        </EventLabel>
        <YearLabel>
          <IconButton
            onClick={prevYear}
            disabled={index <= 0}
            className={`icon ${index <= 0 ? 'disabled' : undefined}`}
          >
            <MdKeyboardArrowLeft />
          </IconButton>
          {partialTimeline.map((year, i) => (
            <Button
              onClick={() => onClick(i)}
              key={`year-${i}`}
              className={`year ${i === currentIndex ? 'active' : undefined}`}
            >
              {year.year}
            </Button>
          ))}
          <IconButton
            onClick={nextYear}
            disabled={index >= timeline.length - 5}
            className={`icon ${
              index >= timeline.length - 5 ? 'disabled' : undefined
            }`}
          >
            <MdKeyboardArrowRight />
          </IconButton>
        </YearLabel>
        <Events>
          {timeline[currentIndex].events.map((event, i) => {
            return (
              <Event className={event.type} key={`event-${i}`}>
                <p className='time'>{event.time}</p>
                <h5>{event.title}</h5>
                <p className='place'>{event.place}</p>
              </Event>
            )
          })}
        </Events>
      </Wrapper>
    </PageContent>
  )
}

export default Timeline

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        history {
          description
          title
        }
      }
    }
  }
`
