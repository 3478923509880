/***************************************\

Timeline data format:
{
  year: 年份（yyyy.mm.dd-mm.dd),
  activitiy: [
    [ "種類", "時間", "名稱", "地點"]
  ]
}

種蕾：
  ev-special     國內外特殊活動  
  ev-ccc         晶晶例行活動
  ev-camp--local 國內營隊
  ev-camp--intl  國際營隊
  
 ***************************************/

const timeline = [
  {
    year: 2021,
    events:[
      { type: "ev-special", 
        time: "2021.12.10", 
        title: "參加《打開希望，聖誕築夢》聖誕點燈", 
        place: "大魯閣湳雅廣場"
      },
      { type: "ev-ccc", 
        time: "2021.12.17", 
        title: "團慶「最愛班ㄐㄧㄡ與高歌」班級音樂會_綠竹班與藍竹班/CONCERT班", 
        place: "台灣儀器科技研究中心/國家高速網路與計算中心"
      },
      { type: "ev-ccc", 
        time: "2021.12.24", 
        title: "團慶「最愛班ㄐㄧㄡ與高歌」班級音樂會_YOUTH 班", 
        place: "台灣儀器科技研究中心"
      },
      { type: "ev-special", 
        time: "2021.12.26", 
        title: "參加新竹街口攻城獅開場表演", 
        place: "新竹縣體育館"
      },
    ]
  },
  {
    year: 2020,
    events:[
      { type: "ev-ccc", 
        time: "2020.01.04", 
        title: "晶晶十九週年團慶音樂會(三) 讓我們唱美麗的歌謠與新竹愛樂管樂團聯合演出", 
        place: "國立清華大學大禮堂"
      },
      { type: "ev-ccc", 
        time: "2020.08.06", 
        title: "2020 晶晶畢業生音樂會_起舞飛揚", 
        place: "新竹縣文化局演奏廳"
      },
      { type: "ev-camp--local", 
        time: "2020.08.21-08.23", 
        title: "晶晶2018阿卡貝拉夏令營", 
        place: "新竹科技生活館"
      },
      { type: "ev-special", 
        time: "2020.11.20", 
        title: "參加風之頌聯合音樂會", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2020.12.19", 
        title: "晶彩二十_晶晶二十週年團慶音樂會", 
        place: "新竹市文化局演藝廳"
      },
    ]
  },
  {
    year: 2019,
    events:[
      { type: "ev-ccc", 
        time: "2019.01.06", 
        title: "晶晶十八週年團慶音樂會系列(二)–初階班與新竹交響管樂團音樂會(森林裡的龍貓)", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2019.01.25-01.27 ", 
        title: "晶晶2019年冬令音樂營", 
        place: "桃園復興青年活動中心"
      },
      { type: "ev-ccc", 
        time: "2019.01.27", 
        title: "晶晶十八週年團慶音樂會系列(三)–階班音樂會", 
        place: "國立新竹中學音樂廳"
      },
    ]
  },
  {
    year: 2018,
    events:[
      { type: "ev-ccc", 
        time: "2018.01.26-01.28",  
        title: "晶晶2018年冬令音樂營", 
        place: "新竹市科學園區科技生活館"
      },
      { type: "ev-ccc", 
        time: "2018.01.28", 
        title: "晶晶十七週年團慶高階班音樂會", 
        place: "國立新竹中學音樂廳"
      },
      { type: "ev-ccc", 
        time: "2018.06.10", 
        title: "囡仔歌晶好聽-晶晶2018年度公演",  
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2018.07.23-08.03", 
        title: "第九屆維也納世界和平合唱藝術節(The 9th World Peace Choral Festival)", 
        place: "Vienna, Austria"
      },
      { type: "ev-special", 
        time: "2018.08.11-08.19", 
        title: "國際兒童阿卡貝拉夏令營(Vocal Asia Festival for Kids)", 
        place: "桃園, 新竹, 台中"
      },
      { type: "ev-ccc", 
        time: "2018.08.24-08.25", 
        title: "晶晶2018年夏令音樂營", 
        place: "大板根森林溫泉渡假中心"
      },
      { type: "ev-ccc", 
        time: "2018.11.18", 
        title: "晶晶十八週年團慶音樂會系列(一 place:)-應}參加台灣合唱協會「新韻之聲詞曲創作音樂會」", 
        place: "台北東吳大學 松怡廳"
      },
    ]
  },
  {
    year: 2017,
    events: [
      { type: "ev-ccc", 
        time: "2017.01.20-01.22", 
        title: "晶晶2017年冬令音樂營", 
        place: "桃園復興青年活動中心"
      },
      { type: "ev-ccc", 
        time: "2017.06.10", 
        title: "晶晶2017年度公演", 
        place: "新竹市文化局演藝廳"
      },
      {type: "ev-camp--local", 
        time: "2017.08.18-08.20", 
        title: "晶晶兒童合唱團與臺東縣兒童合唱團聯合音樂營", 
        place: "新竹市科學園區科技生活館"
      },
      { type: "ev-special", 
        time: "2017.08.23-08.28", 
        title: "參加第九屆輕井澤國際合唱節 Karuizawa International Choral Festival", 
        place: "Karuizawa, Japan"
      },
      { type: "ev-ccc", 
        time: "2017.12.10", 
        title: "晶晶十七週年團慶初階班音樂會", 
        place: "國立新竹中學音樂廳"
      }
    ]
  },
  {
    year: 2016,
    events: [
      { type: "ev-ccc", 
        time: "2016.01.22-01.24", 
        title: "晶晶2016年冬令音樂營", 
        place: "桃園復興青年活動中心"
      },
      { type: "ev-special", 
        time: "2016.05.29", 
        title: "晶晶Youth班與木樓合唱團聯演", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2016.06.05", 
        title: "晶晶2016年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2016.07.02-07.13", 
        title: "參加加拿大 International Choral Kathaumixw 國際兒童合唱音樂節」", 
        place: "Powell River, Canada"
      },
      { type: "ev-camp--local", 
        date: "2016.07.19-07.02", 
        title: "晶晶兒童合唱團與臺東縣兒童合唱團聯合音樂營-乘著氣球飛上天", 
        place: "台東縣文化處藝文中心"
      },
      { type: "ev-ccc", 
        time: "2016.12.01", 
        title: "晶晶十六週年團慶音樂會", 
        place: "國立清華大學南大校區"
      }
    ]
  },
  {
    year: 2015,
    events: [
      { type: "ev-ccc", 
        time: "2015.01.02", 
        title: "團慶三部曲—晶生晶世愛傳萬里音樂會", 
        place: "與美國團聯演"
      },
      { type: "ev-ccc", 
        time: "2015.01.30-02.01", 
        title: "晶晶冬令音樂營", 
        place: "桃園復興鄉青年活動中心"
      },
      { type: "ev-special", 
        time: "2015.02.09-02.18", 
        title: "參加2015紐約卡內基音樂廳冬季音樂會", 
        place: "美國紐約卡內基音樂廳"
      },
      { type: "ev-ccc", 
        time: "2015.06.06", 
        title: "晶晶2015年度公演", 
        place: "新竹市立演藝廳"
      },
      { type: "ev-special", 
        time: "2015.08.07-08.08", 
        title: "協辦第一屆台灣國際兒童合唱觀摩會", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2015.08.21-08.22", 
        title: "晶晶2015年夏令音樂營", 
        place: "大板根森林溫泉渡假中心"
      },
      { type: "ev-ccc", 
        time: "2015.12.26", 
        title: "晶晶十五週年團慶音樂會-晶典", 
        place: "苗北藝文中心演藝廳"
      }
    ]
  },
  {
    year: 2014,
    events: [
      { type: "ev-ccc", 
        time: "2014.01.24-01.26", 
        title: "晶晶冬令音樂營", 
        place: "桃園復興青年活動中心"
      },
      { type: "ev-ccc", 
        time: "2014.06.07", 
        title: "晶晶第十三次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2014.08.13", 
        title: "晶晶與日本夙川兒童合唱團聯演", 
        place: "新竹縣竹北市十興國小"
      },
      { type: "ev-ccc", 
        time: "2014.08.22-08.23", 
        title: "香港循道學校兒童合唱團一同參加晶晶夏令音樂營", 
        place: "大阪根森林溫泉度假村"
      },
      { type: "ev-ccc", 
        time: "2014.12.14", 
        title: "團慶首部曲", 
        place: "台北市木柵動物園音樂會"
      },
      { type: "ev-ccc", 
        time: "2014.12.20", 
        title: "團慶二部曲", 
        place: "三芝雙連安養中心關懷獻唱"
      },
    ]
  },
  {
    year: 2013,
    events: [
      { type: "ev-ccc", 
        time: "2013.01.19-01.21", 
        title: "日本和歌山兒童合唱團來台一同參與晶晶2013冬令音樂營", 
        place: "桃園復興青年活動中心"
      },
      { type: "ev-ccc", 
        time: "2013.06.01", 
        title: "晶晶第十二次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2013.07.26-08.04", 
        title: "晶晶韓國演出", 
        place: "韓國 首爾 及順天灣"
      },
      { type: "ev-ccc", 
        time: "2013.12.15", 
        title: "晶晶第十三週年團慶音樂會", 
        place: "苗北藝文中心"
      },
      { type: "ev-special", 
        time: "2013.12.22", 
        title: "晶晶參加新竹巨城百貨報佳音", 
        place: "新竹市巨城活動中心"
      },
    ]
  },
  {
    year: 2012,
    events: [
      { type: "ev-ccc", 
        time: "2012.02.03-02.05", 
        title: "晶晶2012年冬令音樂營", 
        place: "新竹市聖經書院"
      },
      { type: "ev-special", 
        time: "2012.02.10", 
        title: "與韓國世界展望會兒童合唱團聯演", 
        place: "台北市國家音樂廳"
      },
      { type: "ev-special", 
        time: "2012.02.11", 
        title: "與韓國世界展望會兒童合唱團聯演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2012.04.01", 
        title: "香港童聲合唱團訪台與晶晶聯誼會", 
        place: "國家高速網路與計算中心"
      },
      { type: "ev-ccc", 
        time: "2012.05.27", 
        title: "晶晶第十一次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2012.08.18", 
        title: "參加新竹市傑出演藝團隊匯演", 
        place: "新竹之心"
      },
      { type: "ev-special", 
        time: "2012.09.22", 
        title: "參加101年新竹生活美學饗宴-藝起去趕集", 
        place: "新竹市生活美學館"
      },
      { type: "ev-special", 
        time: "2012.10.14", 
        title: "參加台中市內合唱團年度公演", 
        place: "苗北藝文中心"
      },
      { type: "ev-ccc", 
        time: "2012.12.29", 
        title: "<無限唱吟> 晶晶十二週年團慶音樂會", 
        place: "新竹縣立演藝廳"
      },
    ]
  },
  {
    year: 2011,
    events: [
      { type: "ev-ccc", 
        time: "2011.01.20-01.22", 
        title: "晶晶2011年冬令音樂營", 
        place: "新竹市中華大學"
      },
      { type: "ev-special",   
        time: "2011.03.26", 
        title: "與福爾摩沙合唱團聯演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2011.04.30", 
        title: "與新竹市科園混聲合唱團聯演", 
        place: "國立新竹高中"
        },
      { type: "ev-special", 
        time: "2011.05.07", 
        title: "與德國雲雀合唱團聯演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2011.07.04-07.05", 
        title: "晶晶第十次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2011.07.13-07.18", 
        title: "晶晶2011中國雙城記", 
        place: "北京天津"
      },
      { type: "ev-camp--intl", 
        time: "2011.07.26-08.05", 
        title: "參加史丹福大學國際兒童合唱研習營", 
        place: "美國加州"
      },
      { type: "ev-ccc", 
        time: "2011.08.19-08.21", 
        title: "晶晶夏令音樂營", 
        place: "新竹市聖經書院"
      },
      { type: "ev-ccc", 
        time: "2011.12.18", 
        title: "晶晶十一週年團慶音樂會", 
        place: "新竹縣文化局演藝廳"
      },
    ]
  },
  {
    year: 2010,
    events: [
      { type: "ev-ccc", 
        time: "2010.01.22-01.24", 
        title: "晶晶冬令音樂營", 
        place: "新竹教育大學"
      },
      { type: "ev-ccc", 
        time: "2010.07.04", 
        title: "晶晶第九次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2010.07.23-08.01", 
        title: "參加韓國首爾「世界展望會國際兒童合唱音樂節", 
        place: "韓國首爾"
      },
      { type: "ev-camp--intl", 
        time: "2010.07.27-08.06", 
        title: "參加美國國際兒童合唱研習營", 
        place: "Stanford University"
      },
      { type: "ev-ccc", 
        time: "2010.08.20-08.22", 
        title: "晶晶夏令音樂營", 
        place: "新竹市聖經書院"
      },
      { type: "ev-special", 
        time: "2010.09.18-09.23", 
        title: "受邀參加「江蘇台灣週」閉幕晚會", 
        place: "蘇州科文中心"
      },
      { type: "ev-ccc", 
        time: "2010.12.26", 
        title: "晶晶十週年團慶音樂會－10年足跡", 
        place: "新竹市文化局演藝廳"
      },
    ]
  },
  {
    year: 2009,
    events: [
      { type: "ev-ccc", 
        time: "2009.01.20-01.22", 
        title: "晶晶冬令音樂營", 
        place: "桃園市復興鄉復興青年活動中心"
      },
      { type: "ev-special", 
        time: "2009.05.09", 
        title: "參與華山基金會“我愛你久久久”感恩慈善音樂會演出", 
        place: "新竹市交通大學中正堂"
      },
      { type: "ev-ccc", 
        time: "2009.07.04", 
        title: "晶晶第八次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2009.07.09-07.12", 
        title: "晶晶2009年台灣環島巡迴演唱之旅", 
        place: "屏東、台南、台中"
      },
      { type: "ev-ccc", 
        time: "2009.08.29-08.30", 
        title: "晶晶夏令音樂營", 
        place: "新竹聖經書院"
      },
      { type: "ev-ccc", 
        time: "2009.12.12", 
        title: "九週年團慶音樂會", 
        place: "新竹科學工業園區管理局禮堂"
      },
    ]
  },
  {
    year: 2008,
    events: [
      { type: "ev-ccc", 
        time: "2008.01.18-20", 
        title: "晶晶音樂營", 
        place:  "國立清華大學"
      },
      { type: "ev-ccc", 
        time: "2008.06.28", 
        title: "晶晶第七次年度公演系列一~琴弦上的愛(與天上人間箏樂團聯演)", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2008.07.05", 
        title: "晶晶第七次年度公演系列二,  悅音中的情", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2008.07.11-22", 
        title: "2008美國愛達荷州國際合唱節-文化交流之旅", 
        place: "美國愛達荷州"
      },
      { type: "ev-special", 
        time: "2008.09.26", 
        title: "贊助參與自閉症協會聯合音樂會", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2008.10.22", 
        title: "與德國Vocaldente人聲重唱團聯演", 
        place: "新竹市交通大學中正堂"
      },
      { type: "ev-ccc", 
        time: "2008.12.21", 
        title: "八週年團慶演唱會", 
        place: "新竹科學工業園區管理局禮堂"
      },
      { type: "ev-special", 
        time: "2008.12.27", 
        title: "溫馨拜訪新竹藍天家園", 
        place: "新竹市"
      }
    ]
  },
  {
    year: 2007,
    events: [
      { type: "ev-ccc", 
        time: "2007.06.30", 
        title: "晶晶第六次年度公演", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2007.07.04", 
        title: "晶晶第六次年度公演,與大地五重唱聯演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2007.12.16", 
        title: "七週年團慶與演唱會", 
        place: "六福村"
      }
    ]
  },
  {
    year: 2006,
    events: [
      { type: "ev-special", 
        time: "2006.05.20", 
        title: "與中廣兒童合唱團聯誼觀摩演唱會", 
        place: "三重市體育館"
      },
      { type: "ev-special", 
        time: "2006.05.27", 
        title: "應邀與菲律賓聖多瑪斯合唱團演出", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2006.07.02", 
        title: "晶晶第五次年度公演", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2006.07.05", 
        title: "晶豔人聲,與美國晶晶青少年合唱團聯演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2006.07.10-07.21", 
        title: "2006歐洲行, 音樂外交之旅", 
        place: "瑞典 Stockholm & 奧地利 Vienna, Salzburg"
      },
      { type: "ev-ccc", 
        time: "2006.08.18-08.24", 
        title: "晶晶音樂營", 
        place: "國立清華大學"
      },
      { type: "ev-special", 
        time: "2006.12.10", 
        title: "中廣、高雄市、晶晶兒童合唱團聖誕聯歡觀摩演唱會", 
        place: "新竹科學工業園區管理局禮堂"
      }
    ]
  },
  {
    year: 2005,
    events: [
      { type: "ev-special", 
        time: "2005.03.12", 
        title: "高雄行。與友團高雄市兒童合唱團聯誼觀摩演唱會", 
        place: "高雄市鹽埕國小禮堂"
      },
      { type: "ev-ccc", 
        time: "2005.06.30", 
        title: "晶晶第四次年度公演", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2005.08.21-08.23", 
        title: "晶晶音樂營", 
        place: "新竹市國立清華大學"
      },
      { type: "ev-special", 
        time: "2005.11.20", 
        title: "與絲竹國樂團聯合演出", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2005.12.14", 
        title: "島國旋律演唱會", 
        place: "新竹市文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2005.12.24", 
        title: "五峰、尖石行", 
        place: "新竹縣"
      }
    ]
  },
  {
    year: 2004,
    events: [
      { type: "ev-special", 
        time: "2004.04.16", 
        title: "參加新竹之春音樂節「我喜歡你陪我唱兒歌」演出", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-ccc", 
        time: "2004.06.30", 
        title: "晶晶第三次年度公演", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2004.07.09-07.21", 
        title: "美加行參加晶晶美國團十週年團慶。並於舊金山戴維斯音樂廳(Davis Symphony Hall) 舉行紀念演唱會。旅行演唱跨越美加邊境，與西雅圖及溫哥華的華人合唱團交流，舉辦聯合演唱會。",
        place: "美加"
      },
      { type: "ev-special", 
        time: "2004.10.16", 
        title: "溫馨拜訪榮民之家", 
        place: "新竹榮民之家"
      },
      { type: "ev-special", 
        time: "2004.12.26", 
        title: "回饋與分享音樂會與來台的晶晶美國團同台演出", 
        place: "新竹市國立清華大學"
      },
    ]
  },
  {
    year: 2003,
    events: [
      { type: "ev-special", 
        time: "2003.04.19", 
        title: "與清華大學合唱團聯合演出", 
        place: "新竹市國立清華大學"
      },
      { type: "ev-ccc", 
        time: "2003.11.02", 
        title: "晶晶音樂營", 
        place: "國立交通大學"
      },
      { type: "ev-ccc", 
        time: "2003.12.27", 
        title: "歲末音樂會與晶晶三週年團慶", 
        place: "新竹科學工業園區管理局禮堂"
      }
    ]
  },
  {
    year: 2002,
    events: [
      { type: "ev-special", 
        time: "2002.01.01", 
        title: "與來台的晶晶美國團同台演出", 
        place: "新竹市立演藝廳"
      },
      { type: "ev-ccc", 
        time: "2002.06.15", 
        title: "第二次年度公演", 
        place: "新竹縣文化局演藝廳"
      },
      { type: "ev-special", 
        time: "2002.07.12-07.22", 
        title: "美國行 2002 USA Trip 應晶晶美國團的邀請，晶晶台灣團展開為期10天的美國真情之旅。與美國西岸主要城市(如洛杉磯、舊金山)的華人合唱團體交流。並應邀在洛杉磯的迪士耐樂園演出。", 
        place: "美國"
      },
      { type: "ev-special", 
        time: "2002.12.28", 
        title: "台南行。與友團台南市鳳凰城兒童合唱團聯誼觀摩演唱會", 
        place: "台南市"
      }
    ]
  },
  {
    year: 2001,
    events: [
      { type: "ev-ccc", 
        time: "2001.06.10", 
        title: "第一次年度公演", 
        place: "國立交通大學演藝廳"
      }
    ]
  },
  {
    year: 2000,
    events: [
      { type: "ev-ccc", 
        time: "2000.12.01", 
        title: "台灣晶晶兒童合唱團成立於新竹", 
        place: "新竹"
      }
    ]
  }
];

module.exports = timeline;